.disabled {
  pointer-events: none;
  background-color: var(--gray3);
}

.classification-picker {
  min-height: 16vh;
}

.classification-picker .disabled .main.expanded {
  pointer-events: none;
}

.classification-picker
  .disabled
  .expanded.classification-type-label.input-group-text {
  background-color: var(--gray3);
}

.classification-picker .disabled .right-arrow.input-group-text {
  background-color: var(--gray);
}

.main.collapsed .classification-type-label {
  width: 13.8vw;
}

.main.collapsed input.form-control {
  display: block;
  width: 21vw;
}

.main.expanded .classification-type-label {
  width: 13.8vw;
}

.main.expanded input.form-control {
  width: 0vw;
  display: none;
}

.expanded,
.input-box.expanded.form-control,
.expanded.main .text {
  overflow: hidden;
  background-color: #ffffff;
}

.dropdown-menu {
  width: 37vw;
  margin-left: 0.7vw;
  position: absolute;
  z-index: 50;
  top: 40px;
  overflow: hidden;
  max-height: 0vh;
  cursor: pointer;
}

.dropdown-menu.show {
  max-height: 20vh;
  overflow-y: scroll;
}

.expanded.main .input-group-text .right-arrow {
  width: 2vw;
  color: var(--purple-lt);
}

.right-arrow.input-group-text {
  background-color: var(--purple-lt);
  color: snow;
}

.main.expanded .classification-type-label {
  background-color: rgba(160, 140, 199, 0);
  color: black;
  cursor: pointer;
}

.main.main.collapsed .classification-type-label {
  background-color: rgba(160, 140, 199, 1);
  color: #ffffff;
  cursor: pointer;
}

.selected-list {
  margin-top: 20px;
  width: 45vw;
  margin-left: 1vw;
}

.selected-list-item {
  overflow: hidden;
  white-space: nowrap;
  border-top: 1px solid var(--purple-dr);
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 3px;
}

.right-arrow {
  cursor: pointer;
}

.dropdown-menu .row {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.dropdown-menu .row .code {
  text-align: center;
  border-right: none;
  width: 10%;
}

.code {
  font-weight: bold;
  text-align: left;
  border-right: 1px solid var(--purple-dr);
  padding: 2%;
  padding-top: 0.2%;
  padding-bottom: 0.2%;
}

.label {
  font-weight: bold;
  padding: 2%;
  padding-top: 0.2%;
  padding-bottom: 0.2%;
  overflow-x: hidden;
}

.selected-list-item .remove {
  color: var(--pink);
  font-weight: bold;
  text-align: right;
  padding: 2%;
  padding-top: 0.2%;
  padding-bottom: 0.2%;
  cursor: pointer;
}

.classification-picker .major {
  border-right: 1px solid var(--purple-lt);
}

.classification-picker {
  border-top: 1px solid var(--gray3);
  padding-top: 20px;
}
