a:hover {
  text-decoration: none;
}

a.sidebar-link {
  color: var(--white);
}

a.sidebar-link.active {
  color: var(--pink-dr);
  font-weight: bold;
  pointer-events: none;
}

a.sidebar-link:hover {
  color: var(--purple-dr);
}

.Sidebar-app-mode-container {
  padding: 8px 16px;
}
.Sidebar-app-mode-text {
  color: var(--pink);
  font-weight: 600;
}

.Sidebar-divider {
  border-top: 1px solid var(--white);
  width: 100%;
}
