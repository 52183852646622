.Stepper {
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.Stepper__step {
    position: relative;
    display: table-cell;
    text-align: center;
    padding: 0.5rem;
}

.Stepper__indicator {
    position: relative;
    display: block;
    z-index: 2;
}

.Stepper__label {
    position: relative;
    display: block;
    margin: 0.5rem 0;
    color: #cfd7de;
    z-index: 2;
}

.Stepper__label-active {
    color: var(--pink);
}

.Stepper__info {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #e3e8ec;
    border-radius: 50%;
    background-color: #e3e8ec;
    font-size: 1.25rem;
    line-height: 2.5rem;
    text-align: center;
    color: #fff;
    z-index: 2;
}

.Stepper__panel {
    display: none;
}

.Stepper .Stepper__step:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 1.75rem;
    width: 100%;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
}
.Stepper .Stepper__step:last-child:after {
    display: none;
}

.Stepper--inline .Stepper__indicator,
.Stepper--inline .Stepper__label {
    display: inline-block;
    vertical-align: inherit;
}
.Stepper--inline .Stepper__label {
    text-align: left;
    padding: 0 0.5rem;
    background: #fff;
}

.Stepper--inline.Stepper--bottom .Stepper__label {
    vertical-align: middle;
}
.Stepper--inline.Stepper--bottom .Stepper__step:after {
    top: auto;
    bottom: 1.75rem;
}


.Stepper--vertical .Stepper__step {
    display: block;
    text-align: left;
}

.Stepper--vertical .Stepper__label {
    padding-left: 1rem;
}

.Stepper--vertical .Stepper__indicator,
.Stepper--vertical .Stepper__label {
    display: table-cell;
}

.Stepper--vertical .Stepper__panel {
    margin-left: 3.5rem;
}


.Stepper--vertical .Stepper__step:after {
    content: " ";
    position: absolute;
    left: 1.75rem;
    top: 2.5rem;
    bottom: -0.5rem;
    width: 0;
    height: auto;
    border-left: 0.125rem solid #e3e8ec;
}

.is-active {
    border-color: var(--purple);
    background-color: var(--purple);
}

.step__is-active {
    display: block;
}

.step__not-active {
    display: none;
}

.step__is-complete {
    background: var(--purple);
    opacity: 0.5;
    cursor: pointer;
}

.step__not-complete {
    opacity: 1;
    cursor: default;
}