.login-modal,
.login-modal .modal-content {
  background-color: black;
  color: #ffffff;
}

.login-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.login-modal .modal-footer {
  border-top: none;
  padding-top: 0px;
}

.login-modal input {
  height: 50px;
  border-radius: 10px;
}

.login-modal .btn-block {
  background-color: #c84091;
  border: none;
  height: 50px;
  border-radius: 10px;
}

.login-modal .modal-title h3 {
  font-weight: bold;
}

.login-modal .modal-title h3 span {
  color: #c84091;
}

.login-modal .text-danger {
  font-size: 105%;
}
