.custom-tabs {
  margin-top: 20vh;
}

.custom-tabs ul.nav.nav-pills {
  border-radius: 2px;
  text-align: end;
  border: 2px solid #e9ecef;
  width: 102%;
  margin-left: calc(-2% - 0.5rem);
  padding: 2%;
  height: 100%;
}

.custom-tabs ul.nav.nav-pills li {
  border-radius: 0px;
  font-size: calc(0.8rem + 0.2vw);
  border: 1px solid transparent;
}

.custom-tabs ul.nav.nav-pills li.active {
  background-color: var(--purple);
  width: 102.06%;
  margin-left: calc(-2% - 0.1rem);
  border-radius: 0px;
  font-weight: bold;
  border: 1px solid var(--purple);
  -webkit-animation: expand 0.1s cubic-bezier(0.47, 0, 0.745, 0.715);
  -moz-animation: expand 0.1s cubic-bezier(0.47, 0, 0.745, 0.715);
  -o-animation: expand 0.1s cubic-bezier(0.47, 0, 0.745, 0.715);
  animation: expand 0.1s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.custom-tabs .tab-content {
  height: 100%;
  border: 2px solid #e9ecef;
  padding: 8vh;
  width: 102%;
  border-right: none;
}

.custom-tabs .tab-content .two {
  margin-top: 4vh;
}

.custom-tabs .tab-content .four {
  margin-top: -2vh;
}

.custom-tabs ul.nav-pills .navbar-brand {
  color: var(--purple-dr);
  font-weight: bold;
}

@-webkit-keyframes expand {
  0% {
    background-color: #fff;
    width: 100%;
    margin-left: 0%;
    font-weight: normal;
    border: 1px solid transparent;
  }
  100% {
    background-color: var(--purple);
    width: 102%;
    margin-left: -2%;
    font-weight: bold;
    border: 1px solid var(--purple);
  }
}
