.batch-checkbox {
  box-shadow: none !important;
  margin-left: 0.5em;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-label {
  margin-top: 0;
  display: flex;
  align-items: center;
}
