.App {
  text-align: center;
  width: 110%;
  margin-left: -6%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--black-dr);
  min-height: 89vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-mode-container {
  position: absolute;
  bottom: 10vh;
  right: 5vw;
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.App-mode-text {
  font-size: 0.7em;
  color: var(--pink);
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 2vw;
}

.App-mode-btn {
  background-color: var(--pink);
  border: 0;
}

.App-mode-btn:hover,
.App-mode-btn:active,
.App-mode-btn:focus {
  background-color: var(--purple);
  border: 0;
}

@media (max-width: 900px) {
  .App-mode-container {
    left: 5vw;
    width: 70vw;
  }
}

@media (max-width: 600px) {
  .App-mode-container {
    width: 100%;
    left: 0;
    flex-direction: column;
  }

  .App-mode-text {
    font-size: 0.8em;
    margin-bottom: 1em;
  }
}

.App-link {
  color: #61dafb;
}

.breadcrumb-item a {
  color: var(--black-dr);
}

.breadcrumb-item.active span {
  color: var(--pink-dr);
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  background-color: #fff;
  height: 89vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.breadcrumb {
  position: fixed;
  margin-left: -15px;
  width: 100%;
  z-index: 1;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.btn-purple {
  background-color: var(--blue);
  color: white;
  border: 0px;
}

.btn-purple,
.btn-purple:hover,
.btn-purple:active,
.btn-purple:focus,
.btn-purple:focus-within {
  background-color: var(--purple);
  color: white;
  border: 0px;
}

.btn-full-width {
  width: 100%;
}

.company-info p {
  font-size: 14px;
}

.accept-btn-container {
  -webkit-align-content: flex-end;
  align-content: flex-end;
  display: grid;
  justify-content: flex-end;
}

.accept-btn {
  padding: 0.2rem;
  margin-bottom: 3px;
}

input[type='text'],
input.form-control,
textarea,
textarea.form-control,
select {
  border: 0px solid var(--purple);
  box-shadow: 0.3px 0.3px 0.3px var(--purple);
}

input[type='text']:focus,
input.form-control:focus,
textarea:focus,
textarea.form-control:focus,
.form-control:focus {
  border: 0.5px solid var(--purple);
  box-shadow: 1px 0.5px 1px var(--purple);
  -webkit-animation: activate 0.1s ease-in;
  -moz-animation: activate 0.1s ease-in;
  -o-animation: activate 0.1s ease-in;
  animation: activate 0.1s ease-in;
}

input[type='radio']:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -0px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: var(--purple-dr);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.btn {
  min-width: 180px;
}

.tagging-xbs-description {
  margin-top: calc(24px + 0.66vw);
}

.margin-left-20 {
  margin-left: 20px;
}

@-webkit-keyframes activate {
  0% {
    border-width: 0px;
  }
  100% {
    border-width: 0.5px;
  }
}

.container-breadcrumb {
  position: relative;
}

.container-breadcrumb .buttons {
  position: absolute;
  right: 20%;
}
