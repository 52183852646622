#loader-overflow {
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999 !important;
  pointer-events: none;
  -webkit-animation: animateOpacity 0.5s ease-out;
  -moz-animation: animateOpacity 0.5s ease-out;
  -o-animation: animateOpacity 0.5s ease-out;
  animation: animateOpacity 0.5s ease-out;
}

#loader-spinner {
  margin-top: 47vh;
  margin-left: 45vw;
}

#loader-spinner .spinner-grow {
  z-index: 99999999999 !important;
  width: 4vw;
  height: 4vw;
  color: var(--purple-dr);
}

@-webkit-keyframes animateOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
