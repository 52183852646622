#no-data-container {
  height: 88vh;
  width: 80vw;
  overflow: hidden;
  -webkit-animation: fadeId 0.5s ease-out;
  -moz-animation: fadeId 0.5s ease-out;
  -o-animation: fadeId 0.5s ease-out;
  animation: fadeId 0.5s ease-out;
}
.chart-wrap .bar-wrap .bar.in {
  transform: translateX(0%);
}

@-webkit-keyframes fadeId {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

svg.xl {
  height: 30vh;
  width: 30vw;
  transform: scale(13);
  color: var(--purple);
}

#no-data-container a.btn-purple {
  background-color: var(--white);
  border: 1px solid var(--purple-dr);
  color: var(--purple-dr);
}

#no-data-container a.btn-purple.finish {
  margin-left: 20%;
}

#no-data-container a.btn-purple.next {
  margin-right: 20%;
}
