#main-container {
  margin-top: 75px;
}

.sidebar {
  background-color: var(--black-lt);
  border-right: 1px solid var(--white);
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-75 {
  margin-top: 75px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-left-4vw {
  margin-left: 4vw;
}

.padding-left-1vw {
  margin-left: 1vw;
}

a {
  color: black;
}

a:hover {
  color: var(--purple-dr);
}

.scroll-box {
  height: 10vh;
  overflow-y: scroll;
}
