.data-range-button{
    background: var(--pink);
    color: var(--white);
    border: var(--pink);
    border-radius: 5px;
    padding: 0.3em 0.9em;
}

.data-range-button:hover{
    background: var(--purple);
    color: var(--white);
    border: var(--purple);
}

.data-range-button:focus{
    background: var(--purple);
    color: var(--white);
    border: var(--purple);
}