#custom-alert {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
  color: firebrick;
  background-color: snow;
  border: 0.5px solid firebrick;
  margin-top: 7.5px;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);

  -webkit-animation: fadeInFromNone 0.3s ease-out;
  -moz-animation: fadeInFromNone 0.3s ease-out;
  -o-animation: fadeInFromNone 0.3s ease-out;
  animation: fadeInFromNone 0.3s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
