.details-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px;
    width: 40%;
}

.details-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-right: 15%;
    margin-left: 15%;
}

.details-badge {
    background: var(--purple-lt);
    padding-top: 0.5em;
}

.details-no-data {
    color: var(--pink-dr);
    display: flex;
    font-weight: bolder;
    justify-content: center;
    width: 100%;
}
