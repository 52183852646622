/**** Compendium Table ****/
.compendium-table-head {
  background-color: var(--purple-lt);
  color: var(--white);
}

.compendium-table-row td,
.compendium-table-head th {
  text-align: center;
}

.compendium-table-info-icon {
  font-size: 1.3rem;
}

.compendium-table-info-icon-wrapper {
  border: solid 2px black;
  padding: 7px 13px;
  border-radius: 100%;
}

.compendium-table-distribute-reserve-icon {
  font-size: 1.2rem;
}

.compendium-table-distribute-reserve-icon-wrapper {
  border: solid 2px var(--white);
  padding: 7px 10px;
  border-radius: 100%;
  margin-left: 10px;
}

.compendium-table-info-icon-wrapper:hover,
.compendium-table-distribute-reserve-icon-wrapper:hover {
  cursor: pointer;
  color: var(--pink);
  border-color: var(--pink);
}

.compendium-table .compendium-table-body .table-last-row {
  font-weight: 700;
  background-color: var(--purple-lt);
  color: var(--white);
  text-align: center;
}

.number-of-comps-listed {
  margin-left: 10px;
}

/**** Compendium Modal ****/

.compendium-modal > .modal-dialog > .modal-content {
  background-color: var(--white);
  color: var(--black);
}

.tagging-modal-header {
  position: relative;
  background-color: #c84090;
  height: 40px !important;
  margin: 1px;
}

.tagging-modal-header-title {
  position: absolute;
  top: 8px;
  font-size: 16px !important;
  color: #ffffff !important;
}

.tagging-modal-input-border {
  margin-top: 5px;
  border: 1px solid gray !important;
}

.tagging-modal-button {
  background-color: white !important;
  border: 1px solid #3bb5c1 !important;
  color: #3bb5c1 !important;
}

.tagging-modal-button:disabled {
  background-color: white !important;
  border: 1px solid gray !important;
  color: gray !important;
}

.tagging-modal-button {
  background-color: white !important;
  border: 1px solid #3bb5c1 !important;
  color: #3bb5c1 !important;
}

.compendium-button {
  background-color: var(--purple);
  border-color: var(--purple);
}

.compendium-button:hover,
.compendium-button:active,
.compendium-button:focus {
  background-color: var(--pink);
  border-color: var(--pink);
}

.compendium-modal-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
}

.compendium-modal-body > div {
  width: 45%;
}

.compendium-modal-body > div > p {
  color: var(--pink);
}

.compendium-modal-body > div > p > span {
  color: var(--black);
}

.compendium-modal-classifications > p {
  margin: 0;
  color: var(--pink);
}

.compendium-modal-classifications > ul {
  list-style-type: none;
  color: var(--black);
}

.minor-classifications {
  list-style-type: disc;
  color: var(--purple);
}

.modal-right-side > p {
  margin-bottom: 0;
  margin-top: 3vh;
}

.modal-right-side > p:first-child {
  margin-top: 0;
}

.tagging-history {
  width: 100%;
}

.tagging-history > p {
  color: var(--pink);
  font-weight: 600;
}

.compendium-modal-table-container {
  display: block;
  height: 25vh;
  overflow: scroll;
  width: 100% !important;
}

.modal-table > .compendium-table-head > tr > th {
  width: 12.5% !important;
  max-width: 12.5% !important;
}

.modal-table > tbody > .compendium-table-row {
  background-color: var(--white) !important;
  color: var(--black) !important;
  font-weight: normal !important;
}

.modal-table > tbody > tr > td > p {
  margin-bottom: 1vh;
}

/**** Filters ****/
.compendium_container {
  margin-top: 50px;
  min-width: 100%;
}

.filter-button-container {
  position: relative;
  display: flex;
  height: 60px;
  width: 100% !important;
  background-color: #323232;
  -left: 2px;
  -right: 2px;
  border-radius: 2px;
}

.filter-button-container .dropdown {
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: white;
}

.select::after {
  content: '<>';
  font: 11px 'Consolas', monospace;
  color: #aaa;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.select:hover {
  background-color: #7c8888;
}

.filter-button-container .button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97%;
  color: white;
  padding: 26px;
  margin-top: 1px;
  background-color: #323232;
  cursor: pointer;
}

.display_value {
  line-height: 0.9;
  min-height: 61px;
  padding-bottom: 2px;
}

.filter-button-container .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  color: white;
  padding: 18px;
  margin-top: 1px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  max-height: 61px;
}

.filter-button-container .dropdown-button-searchable {
  padding: 21px;
  width: 125px !important;
}

.filter-button-container .dropdown-button:focus {
  border: none;
  box-shadow: none;
}

.filter-button-container .dropdown-button:hover {
  background-color: var(--black-lt);
}

.filter-button-container .dropdown-button-searchable:hover {
  background-color: var(--black-lt);
}

.filter-button-container .dropdown-button-searchable:focus {
  background-color: var(--black-lt);
}

.dropdown-button::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

.dropdown-button:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.dropdown-button::placeholder {
  color: white;
}

.button:hover {
  cursor: pointer;
  background-color: #7c8888;
}

.filter-button-middle-container {
  display: flex;
  align-items: flex-start;
}

.filter-button-start-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 60px;
}

.container {
  margin-top: 50px;
}

.filter-button-container {
  position: relative;
  height: 62px;
  width: 100% !important;
  background-color: #323232;
}

.filter-button-container .dropdown {
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: white;
}

.select::after {
  content: '<>';
  font: 11px 'Consolas', monospace;
  color: #aaa;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.select:hover {
  background-color: #7c8888;
}

@media (max-width: 1150px) {
  .compendium-table {
    margin-top: 70px;
  }

  .filter-button-start-container {
    position: absolute;
    margin-top: 65px;
    width: 100%;
  }

  .filter-button-container .button {
    width: 100px;
    border-radius: 5px;
  }

  .filter-button-end-container {
    width: 200px !important;
  }

  .dropdown_container {
    max-width: 125px !important;
  }

  .dropdown-button {
    width: 100px !important;
  }

  .search_input {
    max-width: 130px !important;
  }

  .filter-button-container .dropdown-button-searchable {
    width: 125px !important;
  }
}

@media (max-width: 900px) {
  .compendium-table {
    margin-top: 70px;
  }

  .filter-button-container .button {
    width: 80px;
    border-radius: 5px;
  }

  .filter-button-end-container {
    width: 100px !important;
  }

  .dropdown_container {
    max-width: 125px !important;
  }

  .dropdown-button {
    width: 90px !important;
  }

  .search_input {
    max-width: 130px !important;
  }

  .filter-button-container .dropdown-button-searchable {
    width: 125px !important;
  }
}

@media (max-width: 700px) {
  .compendium-table {
    margin-top: 135px;
  }

  .dropdown_container {
    max-width: 125px !important;
  }

  .dropdown-button {
    width: 75px !important;
  }
  .search_input {
    max-width: 120px !important;
  }

  .filter-button-container .dropdown-button-searchable {
    width: 125px !important;
  }
}

@media (max-width: 600px) {
  .filter-button-container .button {
    width: 90px;
    border-radius: 5px;
  }

  .dropdown_container {
    max-width: 75px !important;
  }

  .dropdown-button {
    width: 75px !important;
  }

  .search_input {
    max-width: 125px !important;
  }

  .filter-button-container .dropdown-button-searchable {
    width: 115px !important;
  }
}

.filter-button-container .button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  height: 100% !important;
  color: white;
  padding: 6px;
  margin-left: 2px;
  background-color: #323232;
  border-color: #7c8888;
  border-left-width: 1px;
}

.button:hover {
  cursor: pointer;
  background-color: var(--black-lt);
}

.filter-button-end-container {
  position: absolute;
  top: 2;
  right: 0;
  display: flex;
  align-items: flex-end;
  height: 62px;
  width: 200px;
  margin-right: 1px;
  float: right;
}

.filter_select_item {
  z-index: 1000;
  position: absolute;
  height: 250px;
  background-color: #323232;
  -webkit-box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
}

.filter_selected_items {
  z-index: 1000;
  position: absolute;
  background-color: var(--black-lt);
  -webkit-box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 2px 17px -1px rgba(0, 0, 0, 0.75);
  max-height: 102px;
  overflow-y: scroll;
}

.filter_selected_items .item {
  cursor: pointer;
  color: white;
  padding: 5px;
  width: 100%;
}

.filter_selected_items .item:hover {
  color: white;
  background: #a03374;
}

.titleSelectedItem {
  color: white;
  padding-top: 10px;
  padding-left: 10px;
}

.filter_select_item .item {
  cursor: pointer;
  color: white;
  padding: 5px;
}

.filter_select_item .item_selected {
  cursor: pointer;
  color: white;
  background: #7c8888;
  padding: 5px;
}

.item.last_of_group {
  border-bottom: var(--purple-lt);
  border-bottom-style: solid;
}

.item:hover {
  color: white;
  background: var(--black-lt);
}

.withoutItems {
  margin: 10px;
  text-align: center;
  color: white;
}

.large-dropdown-container {
  width: 200px;
}

.dropdown_container .large {
  width: 200px !important;
}

.dropdown_container {
  max-width: 110px !important;
  position: relative;
}

.dropdown-button {
  outline: none;
  width: 110px;
  height: 100%;
}

.dropdown-button .large {
  width: 200px;
}

.dropdown-button:focus {
  outline: 0;
}

.dropdown-button::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

.dropdown-button:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.dropdown-button::placeholder {
  color: white;
}

.arrow-left {
  position: absolute;
  z-index: 100;
  top: 60px;
  margin-left: 2%;
  margin-top: 1px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #323232;
}

.arrow-center {
  position: absolute;
  z-index: 100;
  top: 60px;
  margin-left: 40%;
  margin-top: 1px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #323232;
}

:focus {
  outline: none;
}

.amountSelected {
  position: absolute;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin: 5px;
  color: white;
  font-size: 10px;
  text-align: center;
  right: 0;
}

.compendium-table {
  min-width: 100%;
}

.container_table_compendium {
  max-width: 100% !important;
  overflow-x: scroll;
}

#hideTooltip {
  display: none;
}

.compendium-modal > .modal-lg {
  max-width: 75% !important;
}

.compendium-modal > .modal-dialog {
  margin: 0 !important;
  margin-left: 20% !important;
}

.state-options-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.state-options-item {
  margin: 10px 20px 0 20px;
}

.compendium-table-download-file {
  font-size: 20px;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.compendium-table-download-file:hover {
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}
