.system-manager-dashboard,
.system-manager-dashboard .table td input {
  font-size: 14px;
}

.system-manager-dashboard .table td input {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.system-manager-dashboard .table td {
  padding: 5px;
  min-width: 4vw;
}

.system-manager-dashboard th {
  font-size: 14px;
  padding: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--purple-lt);
  color: #fff;
  border-left: 1px solid #fff;
  line-height: 18px;
}

.system-manager-dashboard th.empty {
  background-color: var(--gray4);
  border-top-color: var(--gray4);
  border-left-color: var(--gray4);
}

.table-container {
  margin: 0px;
  padding: 0px;
}

.system-manager-dashboard .manager-queue {
  margin-left: 1vw;
  width: 110%;
}

.refresh-data {
  position: fixed;
  z-index: 5;
  top: 90px;
  right: 30px;
  font-size: 20px;
  color: var(--purple-dr);
  cursor: pointer;
}

th.padding-left-10 {
  padding-left: 10px;
}
