:root {
  /* Primary brand colors */
  --blue: #00b0b9;
  --purple: #7559ae;
  --orange: #ea5408;
  --yellow: #cedb00;
  --red: #e63243;
  --brown: #966a58;
  --pink: #c84091;
  --gray: #9cabaa;
  --green: #08d85c;
  --black: #000000;
  /* secondary UI colors */
  --gray1: #909090; /* darkest */
  --gray2: #e3e3e3;
  --gray3: #efefef;
  --gray4: #f9f9f9; /* lightest */
  /* Primary colors darker shade for hower, ... */
  --blue-dr: #008c94;
  --purple-dr: #5d478b;
  --orange-dr: #bb4306;
  --yellow-dr: #a4af00;
  --red-dr: #b82835;
  --brown-dr: #785446;
  --pink-dr: #a03374;
  --gray-dr: #7c8888;
  --green-dr: #06ac49;
  --black-dr: #323232;
  /* Primary colors lighter shade */
  --purple-lt: #a08cc7;
  --purple-lt-hover: #a496dc;
  --pink-lt: #d97bb3;
  --black-lt: #232121;
  /* Font */
  --base-font: sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black-dr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
