#custom-alert.success {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
  color: green;
  background-color: #ffffff;
  border: 0.5px solid green;
  margin-top: 7.5px;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);

  -webkit-animation: animateOpacity 0.3s ease-out;
  -moz-animation: animateOpacity 0.3s ease-out;
  -o-animation: animateOpacity 0.3s ease-out;
  animation: animateOpacity 0.3s ease-out;
}

@-webkit-keyframes animateOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
