.pagination-wrapper {
  display: flex;
  margin: 20px 0;
}

.pagination {
  margin: auto;
}

.pagination li a {
  color: var(--white);
  background-color: var(--blue);
  border: none;
}

.pagination li a:hover {
  color: var(--white);
  background-color: var(--blue-dr);
}

.page-item.active .page-link {
  background-color: var(--blue-dr) !important;
  border: none !important;
}

.pagination li a:focus {
  box-shadow: none;
}
