.batch-process-table__header {
    background: var(--purple-lt);
}
.batch-process-table__header-cell {
     color: var(--white);
     cursor: pointer;
 }

.batch-process-table__header-cell:hover {
     color: var(--white);
     background: var(--purple-lt-hover);
     cursor: pointer;
 }