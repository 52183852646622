.download-repository-download-icon {
  cursor: pointer;
}

.download-repository-table {
  margin-top: 5%;
}

.download-repository-table {
  color: var(--gray1);
  font-size: 0.9em;
}

.download-repository-table-head th {
  border-top: none;
  font-weight: normal;
}

.download-repository-table-body tr {
  transition: all 0.3s ease-in-out;
}

.download-repository-table-body tr:hover {
  color: var(--purple);
  font-weight: 600;
}

.download-repository-actions {
  text-align: center;
}

.download-repository-stats-container {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.stat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px;
}

.stat-circle-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  border: 1px solid var(--gray);
  font-size: 1.5em;
  font-weight: 700;
}

.stat-circle-container.inProgress {
  color: var(--purple);
  border: 1px solid var(--purple);
}

.stat-circle-container.completed {
  color: #fff;
  border: 1px solid var(--purple);
  background-color: var(--purple);
}

.stat-container span {
  font-size: 0.8em;
  color: var(--purple);
  font-weight: 600;
}

/**** Paginated Table****/

.paginated-table-row td,
.paginated-table-head th {
  text-align: center;
}

.paginated-table .paginated-table-body .table-last-row {
  font-weight: 700;
  background-color: var(--purple-lt);
  color: var(--white);
  text-align: center;
}

.paginated-table-head {
  background-color: var(--purple-lt);
  color: var(--white);
}

.paginated-table {
  min-width: 100%;
}

.container-paginated-table {
  max-width: 100%;
  overflow-x: scroll;
}

.paginated-table .paginated-table-body .table-last-row {
  font-weight: 700;
  background-color: var(--purple-lt);
  color: var(--white);
  text-align: center;
}
