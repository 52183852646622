.navbar {
  background-color: black !important;
}

.navbar-nav > li {
  /*border-left: 0.001em solid #E3E3E3;*/
  color: var(--gray);
}

.navbar-nav > li:hover {
  background-color: var(--black-dr);
  color: white !important;
}

.nav-item:hover {
  cursor: pointer;
}

.version {
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.nav-item > div {
  margin: 10px;
}

.navbar-brand {
  margin-left: 2%;
}

.nav-details {
  color: white;
  font-size: medium; /* medium; but it might look better with 0.7em*/
  font-weight: 100;
}

.nav-link {
  border-top: none;
  border-bottom: none;
}
