.custom-tabs.manage-logins {
  margin-top: 0px;
  padding: 0px;
}

.custom-tabs.manage-logins ul.nav.nav-pills {
  border: none;
  width: auto;
  height: auto;
  margin-left: 1vw;
}

.custom-tabs.manage-logins ul.nav.nav-pills li {
  border: none;
}

.custom-tabs.manage-logins .tab-content {
  border: none;
  padding: 8vh;
  width: auto;
  border-right: none;
}

.manage-logins-tab {
  text-align: center;
}

.manage-logins.tab-content {
  padding: 0px !important;
}

.manage-logins.form-row {
  margin-top: 2vh;
}

.manage-logins.form-row .form-control {
  width: 103%;
  margin-left: 0% !important;
}
